import styled from 'styled-components'
import { type DisclaimerData } from '@/features/cms/components/CMS/types'
import { renderElement } from '@/features/cms/components/CMS/RichTextRenderer'
import { CMSErrorHandler } from '@/features/cms/components/CMS/CMSErrorHandler'
import { calcRem } from '@shipt/design-system-themes'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'

/**
 * This component corresponds to the `footer_disclaimer` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/footer_disclaimer
 * - **Production** - https://cms.shipt.com/content-type/pool/footer_disclaimer
 */
export const CMSDisclaimer = ({
  content_type_id,
  id,
  data: { disclaimer_rich_text },
}: DisclaimerData) => {
  try {
    return (
      <DisclaimerTextContainer {...getCMSWrapperProps({ content_type_id, id })}>
        {disclaimer_rich_text.elements.map(renderElement)}
      </DisclaimerTextContainer>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

const DisclaimerTextContainer = styled.div`
  margin-top: 1.25rem;

  * {
    font-size: ${({ theme }) =>
      calcRem(theme.font.static.body.compact.sm.fontSize)}rem;
  }
`
